/* eslint-disable react/display-name */
import { createElement, ReactNodeArray } from "react";
import { Logger } from "@feature-hub/core";
import { GfaLocaleServiceV1 } from "@volkswagen-onehub/gfa-locale-service";
import { VueFormatterServiceInterfaceV1 } from "@volkswagen-onehub/audi-vue-formatter-service";
import {
  renderTextWithFootnotesReferences,
  cleanupEmissionConsumptionMarkup,
  getConsumptionsAndEmissions,
  FootnoteReference,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";
import { MappingProps, mapToTeaserProps } from "./mapToTeaserProps";
import { ParallaxTeaserProps } from "../component";
import { Content } from "../EditorContentTypes";

export async function createInitialState(
  content: Content,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger?: Logger
): Promise<ParallaxTeaserProps> {
  const consumptionsAndEmissionsValues = await getConsumptionsAndEmissions(
    content.legalData.wltpKeys,
    vueFormatterService,
    localeService,
    logger
  );

  const mappingProps: MappingProps = {
    content,
    headline: renderTextWithFootnotesReferences(content.headline),
    copy: renderTextWithFootnotesReferences(content.copy),
    wltpData: [],
  };

  if (content.legalData.additionalText) {
    mappingProps.additionalLegalText = renderTextWithFootnotesReferences(
      content.legalData.additionalText
    );
  }

  consumptionsAndEmissionsValues.forEach((cae) => {
    if (cae?.formattedConsumption && cae?.formattedEmission) {
      mappingProps.wltpData.push({
        formattedConsumption: renderTextWithFootnotesReferences(
          cleanupEmissionConsumptionMarkup(cae.formattedConsumption)
        ),
        formattedEmission: renderTextWithFootnotesReferences(
          cleanupEmissionConsumptionMarkup(cae.formattedEmission)
        ),
      });
    }
  });

  return mapToTeaserProps(mappingProps);
}

interface SerializedWltpProps {
  formattedConsumption: (string | Record<string, unknown>)[] | undefined;
  formattedEmission: (string | Record<string, unknown>)[] | undefined;
}

export const deserializeReactNodeArray = (
  deserializedProperty?: string | (string | Record<string, unknown>)[]
): undefined | string | ReactNodeArray => {
  if (!deserializedProperty || typeof deserializedProperty === "string") {
    // if it's undefined or a string it doesn't contain any footnotes. Nothing to do here
    return deserializedProperty;
  }
  return deserializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === "string") {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <FootnoteReference /> react component
    return createElement(
      FootnoteReference,
      serializedReactNode.props as undefined
    );
  });
};

const deserializeReactNodeArrayInWltpData = (
  wltpData: SerializedWltpProps[]
) => {
  return wltpData.map(({ formattedConsumption, formattedEmission }) => {
    return {
      formattedConsumption: deserializeReactNodeArray(formattedConsumption),
      formattedEmission: deserializeReactNodeArray(formattedEmission),
    };
  });
};

/**
 * Helper function to deserialize the state of the feature app. It converts serialized ReactNodeArray Entries that aren't string to FootnoteReference components
 * @param state
 */
export function deserializeState(state: string): ParallaxTeaserProps {
  const props = JSON.parse(state);
  return {
    ...props,
    headline: deserializeReactNodeArray(props.headline),
    copy: deserializeReactNodeArray(props.copy),
    legalData: {
      ...props.legalData,
      additionalText: deserializeReactNodeArray(props.legalData.additionalText),
      wltpData: deserializeReactNodeArrayInWltpData(props.legalData.wltpData),
    },
  };
}
