import { AudiPlatformProvider } from "@audi/audi-ui-react";
import React, { useState, useEffect, useContext } from "react";
import * as pkg from "../package.json";
import { ParallaxTeaserComponent, ParallaxTeaserProps } from "./component";
import { useTrackingManager } from "./context/useTrackingManager";
import { Context, useLogger } from "./context";
import { AsyncStateHolder } from "./";
import {
  transformToTrackingText,
  useContent,
  useInViewEffect,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";
import { Content } from "./EditorContentTypes";
import { createInitialState } from "./utils/stateUtils";

interface ParallaxTeaserFeatureAppProps extends ParallaxTeaserProps {
  readonly originalHeadline: string;
}

const ParallaxTeaserFeatureApp: React.FunctionComponent<ParallaxTeaserFeatureAppProps> =
  ({ originalHeadline, ...teaserProps }: ParallaxTeaserFeatureAppProps) => {
    const trackingManager = useTrackingManager();

    const linkTracking = (
      href: string,
      text: string,
      clickId: string
    ): void => {
      trackingManager.click(
        transformToTrackingText(originalHeadline),
        href,
        text,
        clickId
      );
    };

    useEffect(() => {
      trackingManager.ready(pkg.version);
    }, []);

    useInViewEffect(() => {
      trackingManager.impression(transformToTrackingText(originalHeadline));
    });

    return (
      <AudiPlatformProvider>
        <ParallaxTeaserComponent {...teaserProps} linkTracking={linkTracking} />
      </AudiPlatformProvider>
    );
  };
ParallaxTeaserFeatureApp.displayName = "ParallaxTeaserFeatureApp";

interface AsyncFeatureApp {
  readonly asyncStateHolder: AsyncStateHolder;
}

const AsyncFeatureApp: React.FunctionComponent<AsyncFeatureApp> = ({
  asyncStateHolder,
}: AsyncFeatureApp) => {
  // when asyncStateHolder is an object it represents the serialized state coming from the server ready to be used as the
  // initial state
  const [state, setState] = useState<ParallaxTeaserProps | undefined>(
    typeof asyncStateHolder === "object" ? asyncStateHolder : undefined
  );

  const { vueFormatterService, localeService } = useContext(Context);

  const content = useContent<Content>();
  const logger = useLogger();

  React.useEffect(() => {
    let mounted = true;
    if (state && content) {
      createInitialState(
        content,
        vueFormatterService,
        localeService,
        logger
      ).then((state) => {
        mounted && setState(state);
      });
    }

    return () => {
      mounted = false;
    };
  }, [content]);

  React.useEffect(() => {
    // when asyncStateHolder is a function it means the state could not be properly serialized by the server and it is
    // not available on the client. In that case this effect will try to fetch the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === "function") {
      logger?.info("SSR did not serialize any state");
      asyncStateHolder().then(setState);
    } else {
      logger?.info("SSR serialized state: ", asyncStateHolder);
    }
  }, []);

  if (!content || !state) {
    return null;
  }

  return (
    <ParallaxTeaserFeatureApp {...state} originalHeadline={content.headline} />
  );
};

export default AsyncFeatureApp;
