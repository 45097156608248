import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import {
  Text,
  Button,
  ButtonGroup,
  Layout,
  LayoutItem,
  ThemeProvider,
  audiDarkTheme,
  audiLightTheme,
} from "@audi/audi-ui-react";
import { addImwidthParameter } from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

import { ImageWidth, toSrcSet } from "./utils/url-utils";
import isMobile from "ismobilejs";
import { ParallaxTeaserProps, WltpProps } from "./ComponentTypes";
export * from "./ComponentTypes";

const StyledImg = styled.img`
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  will-change: transform;
  backface-visibility: hidden;
  max-width: ${ImageWidth.XS}px;
  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    max-width: ${ImageWidth.S}px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    max-width: ${ImageWidth.M}px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    max-width: ${ImageWidth.XL}px;
  }
`;
StyledImg.displayName = "StyledImg";

const ParallaxTeaser = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

ParallaxTeaser.displayName = "ParallaxTeaser";

const ParallaxArea = styled.div`
  position: relative;
  overflow: hidden;
  height: 1000px;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 1400px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    height: 1600px;
  }
`;

ParallaxArea.displayName = "ParallaxArea";

const StyledLayout = styled(Layout)`
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  left: 0;
  z-index: 1;
  will-change: transform;
`;

StyledLayout.displayName = "StyledLayout";

const StyledLayoutItemText = styled(LayoutItem)`
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.ui.primaryInverse};
  padding: var(${(props) => props.theme.responsive.spacing.xxl});
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    position: relative;
    left: 4%;
  }
`;

const LegalArea = styled(Layout)`
  background-color: ${(props) => props.theme.colors.ui.primaryInverse};
`;

LegalArea.displayName = "LegalArea";

const WltpWrapper = styled.span`
  display: block;
`;

const WltpSpan = styled.span`
  display: block;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: inline-block;
    &:first-child {
      margin-right: var(${(props) => props.theme.responsive.spacing.s});
    }
  }
`;

WltpSpan.displayName = "WltpSpan";

class Debouncer {
  private lastTimestamp = 0;

  public debounce(callback: () => void, wait: number) {
    const currentTimestamp = Date.now();

    if (currentTimestamp - this.lastTimestamp >= wait) {
      callback();
      this.lastTimestamp = currentTimestamp;
    }
  }
}

const WltpValuesComponent: React.FC<WltpProps> = ({
  formattedConsumption,
  formattedEmission,
}: WltpProps) => {
  return (
    <WltpWrapper>
      <WltpSpan data-testid={`consumption`}>{formattedConsumption}</WltpSpan>
      <WltpSpan data-testid={`emission`}>{formattedEmission}</WltpSpan>
    </WltpWrapper>
  );
};

export const ParallaxTeaserComponent: React.FC<ParallaxTeaserProps> = (
  parallaxTeaserProps
) => {
  const { headline, copy, links, images, linkTracking, legalData, linkType } =
    parallaxTeaserProps;
  const { imageXS, imageS, imageM, imageXL, altText = "" } = images;

  const [disableAnimation, setDisableAnimation] = useState(true);
  const parallaxRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const textboxRef = useRef<HTMLDivElement>(null);

  const imageScrollRate = 0.25;
  const textScrollRate = -0.125;
  const debouncer = new Debouncer();
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);

  // Fix innerHeight for mobile browsers that hide url- and navigation bar, to avoid flickering on scroll.
  // let windowInnerHeight = window.innerHeight;
  const [windowInnerHeight, setWindowInnerHeight] = useState(0);
  let teaserDOMRect: DOMRect;

  function updateTeaserDOMRect() {
    if (!parallaxRef.current) {
      throw new Error(`The Teaser element could not be found!`);
    }
    teaserDOMRect = parallaxRef.current.getBoundingClientRect();
  }

  function getTeaserDOMRect() {
    if (!teaserDOMRect) {
      updateTeaserDOMRect();
    }
    return teaserDOMRect;
  }

  function isScrolledNearView(): boolean {
    const rect = getTeaserDOMRect();
    const topIsNearView = rect.top / 2 < window.innerHeight;
    const bottomIsNearView = rect.bottom > window.innerHeight / -2;
    return topIsNearView && bottomIsNearView;
  }

  function updateImageTop() {
    imageRef.current &&
      (imageRef.current.style.top = `${
        (windowInnerHeight * imageScrollRate) / -2
      }px`);
  }

  function updateTextboxTop() {
    if (parallaxRef.current && textboxRef.current) {
      const teaserRect = parallaxRef.current.getBoundingClientRect();
      const offsetToCenterTeaser = (windowInnerHeight - teaserRect.height) / 2;
      const isWindowSmallerThanTeaser = windowInnerHeight <= teaserRect.height;

      if (isWindowSmallerThanTeaser) {
        /**
         * If the window is smaller than the teaser, the textbox position will be at the
         * top of the window if the teaser is scrolled to its center.
         * The factor 2 is an additional visual ajustment.
         **/
        textboxRef.current.style.top = `${
          (-offsetToCenterTeaser - offsetToCenterTeaser * textScrollRate) / 2
        }px`;
      } else {
        textboxRef.current.style.top = `${
          -offsetToCenterTeaser * textScrollRate
        }px`;
      }
    }
  }

  function updateParallaxImageTransform(transformY: number) {
    imageRef.current &&
      (imageRef.current.style.transform = `translate3d(-50%, ${transformY}px, 0)`);
  }

  function updateParallaxTextTransform(transformY: number) {
    textboxRef.current &&
      (textboxRef.current.style.transform = `translate3d(0, ${transformY}px, 0)`);
  }

  const updatePosition = useCallback(() => {
    const rect = getTeaserDOMRect();
    const offset = windowInnerHeight - rect.bottom;

    updateParallaxImageTransform(offset * imageScrollRate);
    updateParallaxTextTransform(offset * textScrollRate);
  }, [windowInnerHeight]);

  function resetPosition() {
    updateParallaxImageTransform(0);
    updateParallaxTextTransform(0);
  }

  function update() {
    updateTeaserDOMRect();
    if (isScrolledNearView()) {
      window.requestAnimationFrame(updatePosition);
    }
  }

  const onScroll = useCallback(() => {
    debouncer.debounce(update, 10);
  }, [windowInnerHeight]);

  let orientationChanged = false;
  const onWindowResize = useCallback(() => {
    if (!isMobileBrowser || orientationChanged) {
      setWindowInnerHeight(window.innerHeight);
      orientationChanged = false;
    }
    debouncer.debounce(() => {
      updateImageTop();
      updateTextboxTop();
    }, 10);
    updatePosition();
  }, [isMobileBrowser, windowInnerHeight]);

  const onOrientationChange = useCallback(() => {
    orientationChanged = true;
  }, []);

  function addEventListener() {
    window.addEventListener("scroll", onScroll, { passive: true });
    window.addEventListener("resize", onWindowResize, { passive: true });
    window.addEventListener("orientationchange", onOrientationChange);
  }

  function removeEventListener() {
    window.removeEventListener("scroll", onScroll);
    window.removeEventListener("resize", onWindowResize);
    window.removeEventListener("orientationchange", onOrientationChange);
  }

  function addMediaQueryEventListener(
    mediaQuery: MediaQueryList,
    listener: (e: MediaQueryListEvent) => void
  ) {
    if (typeof mediaQuery.addEventListener === "function") {
      mediaQuery.addEventListener("change", listener, { passive: true });
    } else {
      // Fallback for older Browser e.g. Safari < v5
      mediaQuery.addListener(listener);
    }
  }

  function removeMediaQueryEventListener(
    mediaQuery: MediaQueryList,
    listener: (e: MediaQueryListEvent) => void
  ) {
    if (typeof mediaQuery.removeEventListener === "function") {
      mediaQuery.removeEventListener("change", listener);
    } else {
      // Fallback for older Browser e.g. Safari < v5
      mediaQuery.removeListener(listener);
    }
  }

  function updateDisableAnimation(
    mediaQuery: MediaQueryList | MediaQueryListEvent
  ) {
    setDisableAnimation(mediaQuery.matches);
  }

  function getLinkVariant(linkType: "button" | "link", i: number) {
    if (linkType === "link") {
      return "text";
    } else if (i === 0) {
      return "primary";
    }
    return "secondary";
  }

  // reduce motion
  useEffect(() => {
    if (isMobile(window?.navigator).any) {
      setIsMobileBrowser(true);
    }
    setWindowInnerHeight(window.innerHeight);
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
    updateDisableAnimation(mediaQuery);

    addMediaQueryEventListener(mediaQuery, updateDisableAnimation);

    return () => {
      removeMediaQueryEventListener(mediaQuery, updateDisableAnimation);
    };
  }, []);

  // reduce motion
  useEffect(() => {
    updateImageTop();
  }, [windowInnerHeight]);

  useEffect(() => {
    updateTextboxTop();

    if (disableAnimation) {
      removeEventListener();
      resetPosition();
    } else {
      addEventListener();
      updatePosition();
    }

    return () => removeEventListener();
  }, [disableAnimation, windowInnerHeight]);

  return (
    <ParallaxTeaser>
      <ParallaxArea ref={parallaxRef}>
        <picture>
          <source
            media="(min-width:1440px)"
            srcSet={toSrcSet(imageXL, ImageWidth.XL)}
          />
          <source
            media="(min-width:768px)"
            srcSet={toSrcSet(imageM, ImageWidth.M)}
          />
          <source
            media="(min-width:375px)"
            srcSet={toSrcSet(imageS, ImageWidth.S)}
          />
          <StyledImg
            ref={imageRef}
            srcSet={toSrcSet(imageXS, ImageWidth.XS)}
            src={addImwidthParameter(imageXS, ImageWidth.XS)}
            alt={altText}
            data-testid="image"
          />
        </picture>
        <StyledLayout ref={textboxRef} justify={{ xs: "center", m: "start" }}>
          <StyledLayoutItemText
            spaceStackStart="xxxl"
            basis={{ xs: "86%", m: "56%", xl: "44%" }}
            shrink="0"
          >
            <Text
              as="h2"
              variant="order2"
              weight="bold"
              spaceStackEnd="l"
              data-testid="headline"
            >
              {headline}
            </Text>
            <Text
              as="p"
              variant="copy1"
              spaceStackEnd="xl"
              data-testid="copy-text"
            >
              {copy}
            </Text>
            {!!links?.length && (
              <ButtonGroup
                variant={
                  linkType === "button" ? "block-buttons" : "text-buttons"
                }
              >
                {links?.map(({ href, text }, i) => (
                  <Button
                    href={href}
                    variant={getLinkVariant(linkType, i)}
                    key={i}
                    onClick={() => linkTracking && linkTracking(href, text, "")}
                    data-testid={`cta-${i}`}
                  >
                    {text}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </StyledLayoutItemText>
        </StyledLayout>
      </ParallaxArea>
      {(legalData.wltpData.length > 0 || legalData.additionalText) && (
        <ThemeProvider
          theme={legalData.theme === "dark" ? audiDarkTheme : audiLightTheme}
        >
          <LegalArea justify={"center"} data-testid="legal-text-area">
            <LayoutItem
              basis={{ xs: "86%", m: "92%" }}
              shrink="0"
              spaceStackStart="l"
              spaceStackEnd="xxxl"
            >
              <Text variant="copy2">
                {legalData.wltpData &&
                  legalData.wltpData.map((data, index) => (
                    <WltpValuesComponent {...data} key={index} />
                  ))}
                {legalData.additionalText && (
                  <WltpSpan data-testid="additional-legal-text">
                    {legalData.additionalText}
                  </WltpSpan>
                )}
              </Text>
            </LayoutItem>
          </LegalArea>
        </ThemeProvider>
      )}
    </ParallaxTeaser>
  );
};
