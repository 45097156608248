import { ReactNodeArray } from "react";
import {
  ParallaxTeaserProps,
  WltpProps,
  ImagesProps as ContentImagesProps,
} from "../component/ComponentTypes";
import { Content, ImagesProps } from "../EditorContentTypes";
import { getImageUrls } from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

const mapImages = (image: ImagesProps): ContentImagesProps => {
  return {
    imageXS: getImageUrls(image.imageXS.assetValue)[0],
    imageS: getImageUrls(image.imageS.assetValue)[0],
    imageM: getImageUrls(image.imageM.assetValue)[0],
    imageXL: getImageUrls(image.imageXL.assetValue)[0],
    altText: image.altText,
  };
};

export interface MappingProps {
  content: Content;
  headline: string | ReactNodeArray;
  copy: string | ReactNodeArray;
  additionalLegalText?: string | ReactNodeArray;
  wltpData: WltpProps[];
}

export const mapToTeaserProps = ({
  content,
  headline,
  copy,
  additionalLegalText: additionalText,
  wltpData,
}: MappingProps): ParallaxTeaserProps => ({
  headline,
  copy,
  links: content.links,
  linkType: content.linkType || "link",
  images: mapImages(content.images),
  legalData: {
    theme: content.legalData.theme,
    additionalText,
    wltpData,
  },
});
