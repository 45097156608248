/* eslint-disable react/display-name */
import React from "react";
import { AsyncSsrManagerV1 } from "@feature-hub/async-ssr-manager";
import { SerializedStateManagerV1 } from "@feature-hub/serialized-state-manager";
import { ReactFeatureApp } from "@feature-hub/react";
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from "@feature-hub/core";
import { ContentServiceV1 } from "@volkswagen-onehub/audi-content-service";
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from "@volkswagen-onehub/audi-footnote-reference-service";
import { ITrackingServiceV1 } from "@volkswagen-onehub/audi-tracking-service";
import { GfaLocaleServiceV1 } from "@volkswagen-onehub/gfa-locale-service";
import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from "@volkswagen-onehub/audi-vue-formatter-service";
import AsyncFeatureApp from "./feature-app";
import {
  ContentContextProvider,
  FootnoteContextProvider,
  InViewContextProvider,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";
import { ContextProvider } from "./context";
import { ParallaxTeaserProps } from "./component";
import { createInitialState, deserializeState } from "./utils/stateUtils";

export interface State {
  consumptionsAndEmissionsValues: ConsumptionsAndEmissions[];
}

/**
 * It holds a function to retrieve the State needed by the feature app when it is rendered on the server
 * It holds the State object created from the serialized state on the client
 */
export type AsyncStateHolder =
  | (() => Promise<ParallaxTeaserProps>)
  | ParallaxTeaserProps
  | undefined;

export interface Dependencies extends FeatureServices {
  readonly "s2:logger": Logger;
  readonly "s2:async-ssr-manager"?: AsyncSsrManagerV1;
  readonly "s2:serialized-state-manager"?: SerializedStateManagerV1;
  readonly "audi-content-service": ContentServiceV1;
  readonly "audi-footnote-reference-service": AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly "audi-tracking-service"?: ITrackingServiceV1;
  readonly "dbad:audi-vue-formatter-service": VueFormatterServiceInterfaceV1;
  readonly "gfa:locale-service": GfaLocaleServiceV1;
}

const ParallaxTeaserFeatureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies
> = {
  dependencies: {
    externals: {
      "@audi/audi-ui-react": "^1.4.0",
      "@feature-hub/react": "^2.7.0",
      react: "^16.13.1",
      "react-dom": "^16.13.1",
      "styled-components": "~5.1.1",
    },
    featureServices: {
      "audi-content-service": "^1.0.0",
      "dbad:audi-vue-formatter-service": "^1.0.0",
      "gfa:locale-service": "^1.0.0",
      "audi-footnote-reference-service": "^3.0.0",
    },
  },
  optionalDependencies: {
    featureServices: {
      "s2:logger": "^1.0.0",
      "audi-tracking-service": "^1.0.0",
      "s2:async-ssr-manager": "^1.0.0",
      "s2:serialized-state-manager": "^1.0.0",
    },
  },

  create: ({ featureServices }) => {
    const {
      "audi-tracking-service": trackingService,
      "audi-content-service": contentService,
      "s2:logger": logger,
      "dbad:audi-vue-formatter-service": vueFormatterService,
      "gfa:locale-service": localeService,
      "audi-footnote-reference-service": footnoteReferenceServiceScopeManager,
      "s2:async-ssr-manager": asyncSsrManager,
      "s2:serialized-state-manager": serializedStateManager,
    } = featureServices;

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    if (trackingService) {
      // set feature app name as early as possible
      trackingService.featureAppName = "audi-parallax-teaser";
    }

    const content = contentService.getContent();
    let asyncStateHolder: AsyncStateHolder;
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          if (content) {
            asyncStateHolder = await createInitialState(
              content,
              vueFormatterService,
              localeService,
              logger
            );
            serializedStateManager?.register(() =>
              JSON.stringify(asyncStateHolder)
            );
          }
        })()
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        asyncStateHolder = deserializeState(serializedState);
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`
        );
        if (content) {
          asyncStateHolder = () =>
            createInitialState(
              content,
              vueFormatterService,
              localeService,
              logger
            );
        }
      }
    }

    return {
      render: () => {
        return (
          <InViewContextProvider logger={logger}>
            <ContentContextProvider contentService={contentService}>
              <FootnoteContextProvider
                footnoteReferenceService={footnoteReferenceService}
              >
                <div
                  data-fefa-custom-id={footnoteReferenceService.getConsumerId()}
                >
                  <ContextProvider featureServices={featureServices}>
                    <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
                  </ContextProvider>
                </div>
              </FootnoteContextProvider>
            </ContentContextProvider>
          </InViewContextProvider>
        );
      },
    };
  },
};

export default ParallaxTeaserFeatureAppDefinition;
