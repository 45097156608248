import React, { useRef, RefObject, useContext } from "react";
import { Logger } from "@feature-hub/core";
import { AsyncSsrManagerV1 } from "@feature-hub/async-ssr-manager";
import { SerializedStateManagerV1 } from "@feature-hub/serialized-state-manager";
import { ITrackingServiceV1 } from "@volkswagen-onehub/audi-tracking-service";

import { Dependencies } from "./../index";
import { VueFormatterServiceInterfaceV1 } from "@volkswagen-onehub/audi-vue-formatter-service";
import { GfaLocaleServiceV1 } from "@volkswagen-onehub/gfa-locale-service";

interface State {
  readonly logger?: Logger;
  readonly trackingService?: ITrackingServiceV1;
  readonly inViewRef: RefObject<HTMLDivElement>;
  readonly asyncSsrManager?: AsyncSsrManagerV1;
  readonly serializedStateManager?: SerializedStateManagerV1;
  readonly vueFormatterService: VueFormatterServiceInterfaceV1;
  readonly localeService: GfaLocaleServiceV1;
}

interface ContextValue {
  readonly featureServices: Dependencies;
  readonly children: JSX.Element;
}

export const Context = React.createContext<State>({} as State);

export const ContextProvider = (props: ContextValue): JSX.Element => {
  const { featureServices, children } = props;
  const {
    "dbad:audi-vue-formatter-service": vueFormatterService,
    "gfa:locale-service": localeService,
  } = featureServices;
  const logger = featureServices["s2:logger"];
  const trackingService = featureServices["audi-tracking-service"];
  const inViewRef = useRef<HTMLDivElement>(null);
  const asyncSsrManager = featureServices["s2:async-ssr-manager"];
  const serializedStateManager = featureServices["s2:serialized-state-manager"];

  const state: State = {
    logger,
    trackingService,
    inViewRef,
    asyncSsrManager,
    serializedStateManager,
    vueFormatterService,
    localeService,
  };

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};

export const useLogger = (): Logger | undefined => useContext(Context).logger;
