import { addImwidthParameter } from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

export enum ImageWidth {
  XS = 374,
  S = 767,
  M = 1439,
  XL = 1920,
}

export const toSrcSet = (imageUrl: string, imageWidth: ImageWidth): string => {
  try {
    return `${addImwidthParameter(imageUrl, imageWidth)} 1x, ${imageUrl} 2x`;
  } catch (e) {
    return imageUrl;
  }
};
